.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 200px;
}

.card .name{
    font-weight: bold;
    margin: 0;
    padding: 0;
    flex: 1;
    align-self: self-end;
}

.card .preview img{
  width: 100%;
  //width: 200px;
  height: 300px;
  object-fit: cover;
}

.card .preview img .overlay.show{
  height:100%;
}

.card .controls span {
  cursor: pointer;
}